:root {
  // @isa-app/shell
  --shell-notification-counter-background: #f70400;
  --shell-notification-counter-text: #fff;

  // @shell/footer
  --shell-footer-background: #fff;
  --shell-footer-link-active: #1f466c;
  --shell-footer-link-inactive: #557596;
  --shell-footer-link-disabled: #d7e6f4;

  // @shell/process
  --shell-process-add-icon-text: #fff;
  --shell-process-add-icon-background: #f70400;
  --shell-process-add-label: #f70400;
  --shell-process-border-active: #f70400;
  --shell-process-background: #fff;

  // @core/toast
  --toast-background: #1f466c;
}

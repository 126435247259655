body.branch {
  --bg-color: #edeff0;

  // @isa-app/scrollbar
  --scrollbar-color: #596470;

  // @shell/header
  --shell-header-button-color: #89949e;
  --shell-header-button-color-active: #586470;
  --shell-header-button-color-disabled: #c6cbd2;
  --shell-header-switch-background: #edeff0;
  --shell-header-switch-color: #596470;
  --shell-header-label-color: #000000;
  --shell-header-label-color-active: #ffffff;

  // @shell/breadcrumb
  --shell-breadcrumb-background: var(--bg-color);

  // @shell/footer
  --shell-footer-link-active: #596470;
  --shell-footer-link-inactive: #9ca5b0;
  --shell-footer-link-disabled: #c6cbd2;

  // @shell/process
  --shell-process-text-active: #596470;
  --shell-process-text-inactive: #9ca5b0;
  --shell-process-badge-background: #edeff0;
  --shell-process-badge-active: #596470;

  // @core/toast
  --toast-background: #596470;
}

body.customer {
  --bg-color: #e6eff9;

  // @isa-app/scrollbar
  --scrollbar-color: #1f466c;

  // @shell/header
  --shell-header-button-color: #9db2c6;
  --shell-header-button-color-active: #557596;
  --shell-header-button-color-disabled: #d7e6f4;
  --shell-header-switch-background: #e6eff9;
  --shell-header-switch-color: #1f466c;
  --shell-header-label-color: #000000;
  --shell-header-label-color-active: #ffffff;

  // @shell/breadcrumb
  --shell-breadcrumb-background: var(--bg-color);

  // @shell/footer
  --shell-footer-link-active: #1f466c;
  --shell-footer-link-inactive: #557596;
  --shell-footer-link-disabled: #d7e6f4;

  // @shell/process
  --shell-process-text-active: #1f466c;
  --shell-process-text-inactive: #557596;
  --shell-process-badge-background: #e6eff9;
  --shell-process-badge-active: #1f466c;

  // @page/dashboard
  --page-dashboard-card-title-color: #1f466c;

  // @core/toast
  --toast-background: #1f466c;
}

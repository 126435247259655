/* You can add global styles to this file, and also import other style files */
@import '~@angular/cdk/overlay-prebuilt.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './scss/components';

@import './scss/root';
@import './scss/customer';
@import './scss/branch';

@layer base {
  body {
    @apply bg-background;
  }

  ::-webkit-scrollbar {
    width: 0; // remove scrollbar space
    height: 0;
    background: transparent; // optional: just make scrollbar invisible */
  }

  .desktop .scroll-bar::-webkit-scrollbar {
    @apply w-3;
    background-color: transparent;
  }

  .desktop .scroll-bar::-webkit-scrollbar-track {
    // @apply my-4;
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: white;
  }

  .desktop .scroll-bar::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: var(--scrollbar-color);
  }
}

@keyframes load {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

.skeleton {
  @apply block bg-gray-300 h-6;
  animation: load 1s ease-in-out infinite;
}

@layer components {
  .input-control {
    @apply rounded border border-solid border-[#AEB7C1] px-4 py-[1.125rem] outline-none;
  }

  // .input-control:focus,
  // .input-control:not(:placeholder-shown) {
  //   @apply bg-white;
  // }

  .input-control.ng-touched.ng-invalid {
    @apply border-brand;
  }
}
